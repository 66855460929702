import React from "react"
import Layout from "@components/layout"
import SEO from "@components/seo"

const PrivacyPage = () => (
  <Layout>
    <SEO title="Privacy" />
    <div className="privacy-page p-5">
      <img
        className="logo text-left mb-3 lazyload"
        data-src="/images/logo-white.svg"
        alt=""
      />
      <div className="text-left">
        <p>
          <strong>LABS42 PRIVACY POLICY</strong>
          <br />
        </p>

        <p>
          <strong>CONTENTS</strong>
          <br />
        </p>

        <ol>
          <li>INTRODUCTION</li>
          <li>CONTROLLER</li>
          <li>PROCESSING OF PERSONAL DATA ON LABS42 WEBSITE(S)</li>
          <li>SECURITY</li>
          <li>YOUR RIGHTS</li>
          <li>EXCLUSIONS</li>
          <li>OTHER TERMS &amp; CONDITIONS</li>
          <li>CONTACT INFORMATION</li>
          <li>VALIDITY AND DOCUMENT MANAGEMENT</li>
        </ol>

        <p>
          <strong>1. Introduction</strong>
          <br />
        </p>

        <p>
          Welcome to the website Labs42, a Labs Software SRL
          product. This Site is operated by
          Labs42 and has been created to provide information about our company for hiring software developers
          and managing software development projects, mobile applications and
          related services.
          <br />
        </p>

        <p>
          Appropriate use and protection of personal data is our highest
          priority. Thus, the following policy is designed to inform you about
          the processing of your personal data and your rights according to the
          General Data Protection Regulation (GDPR) and other data protection laws.
          <br />
        </p>

        <p>
          <strong>2. Controller</strong>
          <br />
        </p>

        <p>
          Where we, Labs42, are the Controller of personal data according to
          Privacy Laws, we are responsible for processing such data in the
          manner set forth herein.
          <br />
        </p>

        <p>
          <strong>3. Processing of Personal Data on Labs42 Website(s)</strong>
          <br />
        </p>

        <p>
          When you interact with us through the Services, we may collect
          Personal Data and other information from you, as further described
          below:
          <br />
        </p>

        <p>
          <strong>Personal Data That You Provide Through the Services: </strong>
          We collect Personal Data from you when you voluntarily provide such
          information, such as when you contact us with inquiries, respond to
          one of our surveys, register for access to the Services or use certain
          Services. Wherever Labs42 collects Personal Data, we make an effort to
          provide a link to this Privacy Policy. By voluntarily providing us
          with Personal Data, you are consenting to our use of it in accordance
          with this Privacy Policy. If you provide Personal Data to the
          Services, you acknowledge and agree that such Personal Data may be
          transferred from your current location to the offices and servers of
          Labs42 and the authorized third parties referred to herein located in
          the US and Europe.
          <br />
        </p>

        <p>
          <strong>Non-Identifiable Data: </strong>When you interact with Labs42
          through the Services, we receive and store certain personally
          non-identifiable information. Such information, which is collected
          passively using various technologies, cannot presently be used to
          specifically identify you. Labs42 may store such information itself,
          or such information may be included in databases owned and maintained
          by Labs42 affiliates, agents or service providers. The Services may
          use such information and pool it with other information to track, for
          example, the total number of visitors to our Site, the number of
          visitors to each page of our Site, and the domain names of our
          visitors' Internet service providers. It is important to note that no
          Personal Data is available or used in this process.
          <br />
        </p>

        <p>
          <strong>Aggregated Personal Data:</strong> In an ongoing effort to
          better understand and serve our clients, Labs42 often
          conducts research on its customer demographics, interests and behavior
          based on the Personal Data and other information provided to us. This
          research may be compiled and analyzed on an aggregate basis, and
          Labs42 may share this aggregate data with its affiliates, agents and
          business partners. This aggregate information does not identify you
          personally. Labs42 may also disclose aggregated user statistics in
          order to describe our services to current and prospective business
          partners, and to other third parties for other lawful purposes.
          <br />
        </p>

        <p>
          <strong>Third Party Tracking and Analytics:</strong> We may allow
          third party service providers to use cookies or similar technologies
          to collect information about your browsing activities over time and
          across different websites following your use of the Services. For
          example, we may use Google Analytics and LinkedIn Tracking Pixels. Such services 
          use cookies or similar technologies to help us analyze how users
          function and use our Site to improve our
          Service. We may also implement Google Analytics features based on
          Display Advertising (which may include Remarketing with Google
          Analytics). Visitors can opt out by using Google's Ads Settings or
          going to tools.google.com/dlpage/gaoptout/.
          <br />
        </p>

        <p>
          Your visit to our website and/or use of our online services will be
          logged. The IP address currently used by your device, the date and
          time, the browser type, the operating system, and/or the webpages
          accessed may be recorded. This data is collected for the purposes of
          optimizing and improving our website and/or online services. The
          processing is legally based on legitimate interest as it is in our
          legitimate interest to protect our website and to improve the quality
          of our services. Additionally, your personal data is only stored if
          you provide it to us, e.g. as part of a registration, a survey, an
          online application, or for online purchase (performance of a
          contract). We have taken appropriate measures to ensure that the data
          provided to us during registration is adequately protected. These
          measures include, but are not limited to, data encryption, access
          control, segregation of duties, and internal audit.
          <br />
        </p>

        <p>
          <strong>a) Blog: </strong>If you wish, you can subscribe to our blog
          by filling out the registration form provided there. The personal data
          collected in the registration form will only be processed for sending
          newsletters to your e- mail address and only if you have given your
          consent to this data processing. Your personal data will be processed
          until you unsubscribe from the newsletter by clicking the link
          unsubscribe, which is provided in each newsletter you receive from
          us. Please note that you will not receive any newsletters from us
          after you unsubscribe.
          <br />
        </p>

        <p>
          <strong>b) Cookie Policy:</strong> In operating the Services, we may
          use a technology called "cookies." A cookie is a piece of information
          that the computer that hosts our Services gives to your browser when
          you access the Services. They are stored as small text files on your
          device. Our cookies help provide additional functionality to the
          Services and help us analyze Services usage more accurately. To make
          your visit to our website more pleasant, and to enable the use of
          certain functions, we may use cookies on various pages. For
          instance, our Site may set a cookie on your browser that allows you to
          access the Services without needing to remember and then enter a
          password more than once during a visit to the Site.
          <br />
        </p>

        <p>
          We use cookies for different purposes including essential cookies to
          authenticate users and prevent fraudulent use of user accounts, to
          enable certain functions of the Service, to provide analytics, to
          store your preferences, and to enable advertisement delivery. In
          addition to our own cookies, we may also use various third-party
          cookies to report usage statistics of the Service. In all cases in
          which we use cookies, we will not collect Personal Data except with
          your permission.
          <br />
        </p>

        <p>
          Some of the cookies we use are deleted after the end of the browser
          session (when you close your browser). Other cookies remain on your
          device and enable us, or our partner companies and other authorized
          third parties, to recognize your browser on your next visit. Browsers
          can be customized and/or configured to accept or reject cookies based
          on your personal preference. On most web browsers, you will find a
          help section on the toolbar. For more information, see the help
          section of your Internet browser. If cookies are not accepted, the
          functionality of our website may be limited, you might not be able to
          use all of the features we offer or store your preferences, and some
          of our pages might not display properly.
          <br />
        </p>

        <p>
          <strong>g) Retention Period: </strong>Personal data provided to us via
          our website will only be stored until the purpose for which they were
          processed has been satisfied. Insofar as retention periods under
          commercial and tax law must be observed, the storage period for
          certain data can be up to 3 years. However, storage periods may also
          be amended due to our legitimate interest (e.g. to guarantee data
          security, to prevent misuse, or to prosecute criminal offenders).
          <br />
        </p>

        <p>
          <strong>
            h) Our Disclosure of your Personal Data &amp; Other Information
          </strong>
          : Labs42 is not in the business of selling your information. We
          consider this information to be a vital part of our relationship with
          you. There are, however, certain circumstances in which we may share
          your Personal Data with certain third parties without further notice
          to you, as set forth below:
          <br />
        </p>

        <p>
          <strong>Business Transfers:</strong> As we develop our business, we
          might sell or buy businesses or assets. In the event of a corporate
          sale, merger, reorganization, dissolution or similar event, Personal
          Data may be part of the transferred assets.
          <br />
        </p>

        <p>
          <strong>Related Companies:</strong> We may also share your Personal
          Data with our Related Companies for purposes consistent with this
          Privacy Policy.
          <br />
        </p>

        <p>
          <strong>Agents, Consultants and Related Third Parties:</strong>{" "}
          Labs42, like many businesses, sometimes hires other companies to
          perform certain business-related functions. Examples of such functions
          include mailing information, maintaining databases and processing
          payments. When we employ another entity to perform a function of this
          nature, we only provide them with the information that they need to
          perform their specific function.
          <br />
        </p>

        <p>
          <strong>Legal Requirements: </strong>Labs42 may disclose your Personal
          Data if required to do so by law or in the good faith belief that such
          action is necessary to (i) comply with a legal obligation, (ii)
          protect and defend the rights or property of Labs42, (iii) act in
          urgent circumstances to protect the personal safety of users of the
          Services or the public, or (iv) protect against legal liability.
          <br />
        </p>

        <p>
          <strong>4. Security</strong>
          <br />
        </p>

        <p>
          Labs42 takes reasonable steps to protect the Personal Data provided
          via the Services from loss, misuse, and unauthorized access,
          disclosure, alteration, or destruction. However, no Internet or email
          transmission is ever fully secure or error free. In particular, email
          sent to or from the Services may not be secure. Therefore, you should
          take special care in deciding what information you send to us via
          email. Please keep this in mind when disclosing any Personal Data to
          Labs42 via the Internet.
          <br />
        </p>

        <p>
          <strong>5. Your Rights</strong>
          <br />
        </p>

        <p>
          As a data subject, you can contact our Data Protection Officer at any
          time with a notification according to the contact information detailed
          above (Section 1) to make use of your rights. These rights are the
          following:
          <br />
        </p>

        <p>
          The right to receive information about the data processing and a copy
          of the processed data;
        </p>

        <p>
          The right to demand the rectification of inaccurate data or the
          completion of incomplete data;
        </p>

        <p>The right to demand the erasure of personal data;</p>

        <p>The right to demand the restriction of the data processing;</p>

        <p>
          The right to receive the personal data concerning the data subject in
          a structured, commonly used and machine-readable format and to request
          the transmittance of these data to another controller;
        </p>

        <p>The right to object to the data processing;</p>

        <p>
          The right to withdraw a given consent at any time to stop a data
          processing that is based on your consent;
          <br />
        </p>

        <p>
          <strong>6. Exclusions</strong>
          <br />
        </p>

        <p>
          This Privacy Policy does not apply to any Personal Data collected by
          Labs42 other than Personal Data collected through the Services. This
          Privacy Policy shall not apply to any unsolicited information you
          provide to Labs42 through the Services or through any other means.
          This includes, but is not limited to, information posted to any public
          areas of the Services, such as forums, any ideas for new products or
          modifications to existing products, and other unsolicited submissions
          (collectively, Unsolicited Information). All Unsolicited Information
          shall be deemed to be non-confidential and Labs42 shall be free to
          reproduce, use, disclose, and distribute such Unsolicited Information
          to others without limitation or attribution.
        </p>

        <p>
          <strong>This Privacy Policy applies only to the Services.</strong> The
          Services may contain links to other web sites not operated or
          controlled by Labs42 (the Third Party Sites). The policies and
          procedures we described here do not apply to the Third Party Sites.
          The links from the Services do not imply that Labs42 endorses or has
          reviewed the Third Party Sites. We suggest contacting those sites
          directly for information on their privacy policies.
          <br />
        </p>

        <p>
          <strong>7. Other Terms &amp; Conditions</strong>
          <br />
        </p>

        <p>
          Your access to and use of the Services is subject to the Terms of
          Service at labs42.com/privacy.
          <br />
        </p>

        <p>
          To keep your Personal Data accurate, current, and complete, please
          contact us as specified below. We will take reasonable steps to update
          or correct Personal Data in our possession that you have previously
          submitted via the Services. Furthermore, please contact us for any
          requests for a copy of your user data or to deactivate your account.
          <br />
        </p>

        <p>
          <strong>8. Contact Information</strong>
          <br />
        </p>

        <p>
          Data Protection Officer You can contact our Data Protection Officer at
          any time by using the following contact details:
          <br />
        </p>

        <p>By Phone: +373 69481195</p>

        <p>
          By e-mail: hello@labs42.io
          <br />
        </p>

        <p>
          <strong>9. Validity and document management</strong>
          <br />
        </p>

        <p>This document is valid as of May 1, 2020.</p>

      </div>
    </div>
  </Layout>
)

export default PrivacyPage